import React from 'react';
import { Check } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const plans = [
  {
    name: 'Basic',
    price: '1%',
    description: 'Best for small loans',
    features: [
      'Borrow up to $25,000',
      '12-month term',
      'Weekly payments',
      'Basic support',
      'Standard security',
      'Email support',
      'Regular updates',
    ],
  },
  {
    name: 'Pro',
    price: '0.8%',
    description: 'Best for medium loans',
    features: [
      'Borrow up to $100,000',
      'Up to 24-month term',
      'Flexible payments',
      'Priority support',
      'Enhanced security',
      'Market analytics',
      'API access',
    ],
  },
  {
    name: 'Enterprise',
    price: '0.5%',
    description: 'Best for large loans',
    features: [
      'Unlimited borrowing',
      'Custom term length',
      'Custom payment schedule',
      '24/7 dedicated support',
      'Maximum security',
      'Advanced analytics',
      'Custom integrations',
    ],
  },
];

export default function Pricing() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      
      {/* SEO-optimized meta title and description */}
      <div className="hidden">
        <h1>Bitcoin Lending Platform Pricing - Competitive Rates Starting at 0.5% APR</h1>
        <p>
          Compare our flexible Bitcoin lending plans. Borrow against your BTC with
          competitive interest rates, customizable terms, and enterprise-grade security.
        </p>
      </div>

      {/* Compact Hero Section */}
      <div className="relative pt-32 pb-12 bg-gradient-to-br from-gray-900 via-gray-800 to-orange-900">
        <div className="absolute inset-0 overflow-hidden">
          <div 
            className="absolute inset-0 opacity-10"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f97316' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
              backgroundRepeat: 'repeat'
            }}
          />
        </div>

        <div className="relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
                Simple, Transparent Pricing
              </h1>
              <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-300">
                Choose the plan that works best for you. All plans include our core features with no hidden fees.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {plans.map((plan) => (
              <div
                key={plan.name}
                className="flex flex-col bg-white rounded-2xl shadow-lg p-8 border border-gray-100 hover:border-orange-500 transition-colors duration-300"
              >
                <div className="flex-grow">
                  <h3 className="text-2xl font-bold text-gray-900 mb-2">{plan.name}</h3>
                  <div className="mb-4">
                    <span className="text-5xl font-bold text-gray-900">{plan.price}</span>
                    <span className="text-gray-600 ml-2">APR</span>
                  </div>
                  <p className="text-gray-600 mb-6">{plan.description}</p>
                  <ul className="space-y-4 mb-8">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <Check className="h-5 w-5 text-orange-500 mr-2 flex-shrink-0" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <button className="w-full bg-orange-500 text-white py-3 px-4 rounded-xl hover:bg-orange-600 transition-colors duration-300 font-medium mt-auto">
                  Get Started
                </button>
              </div>
            ))}
          </div>

          {/* FAQ Section for SEO */}
          <div className="mt-20">
            <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
              Frequently Asked Questions
            </h2>
            <div className="grid gap-8 md:grid-cols-2">
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  How is the interest rate calculated?
                </h3>
                <p className="text-gray-600">
                  Our interest rates are calculated based on your chosen plan, loan amount,
                  and term length. The APR includes all fees and is fixed for the duration
                  of your loan.
                </p>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  What happens if Bitcoin's price drops?
                </h3>
                <p className="text-gray-600">
                  We maintain a conservative loan-to-value ratio to protect against market
                  volatility. You'll receive notifications if the price approaches critical
                  levels, giving you time to add collateral or repay the loan.
                </p>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Is my Bitcoin safe?
                </h3>
                <p className="text-gray-600">
                  Yes, your Bitcoin is stored in institutional-grade cold storage with
                  multi-signature security. We use industry-leading custody solutions and
                  maintain comprehensive insurance coverage.
                </p>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Can I repay my loan early?
                </h3>
                <p className="text-gray-600">
                  Yes, you can repay your loan at any time with no prepayment penalties.
                  Early repayment can help you save on interest charges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}