import React, { useEffect, useState } from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';

interface CoinData {
  symbol: string;
  price: number;
  change: number;
}

const fallbackData: CoinData[] = [
  { symbol: 'BTC', price: 67234.52, change: 2.45 },
  { symbol: 'ETH', price: 3845.67, change: 1.23 },
  { symbol: 'BNB', price: 567.89, change: -0.45 },
];

export default function MarketTicker() {
  const [coins, setCoins] = useState<CoinData[]>(fallbackData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,binancecoin&vs_currencies=usd&include_24hr_change=true'
        );
        
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        
        const formattedData: CoinData[] = [
          {
            symbol: 'BTC',
            price: data.bitcoin?.usd || fallbackData[0].price,
            change: data.bitcoin?.usd_24h_change || fallbackData[0].change,
          },
          {
            symbol: 'ETH',
            price: data.ethereum?.usd || fallbackData[1].price,
            change: data.ethereum?.usd_24h_change || fallbackData[1].change,
          },
          {
            symbol: 'BNB',
            price: data.binancecoin?.usd || fallbackData[2].price,
            change: data.binancecoin?.usd_24h_change || fallbackData[2].change,
          },
        ];

        setCoins(formattedData);
      } catch (error) {
        console.warn('Using fallback market data:', error);
        setCoins(fallbackData);
      } finally {
        setLoading(false);
      }
    };

    fetchPrices();
    const interval = setInterval(fetchPrices, 60000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="bg-gray-900 text-white py-1">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-center">
            <div className="bg-gray-800/50 rounded-lg px-4 py-2">
              <span className="text-gray-400">Loading market data...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 text-white py-1">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-center">
          <div className="overflow-x-auto no-scrollbar w-full max-w-[360px] sm:max-w-none">
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg px-4 py-1.5 inline-flex space-x-6 min-w-full sm:min-w-0">
              {coins.map((coin) => (
                <div
                  key={coin.symbol}
                  className="flex items-center space-x-2 whitespace-nowrap"
                >
                  <span className="font-medium">{coin.symbol}</span>
                  <span>${coin.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}</span>
                  <span
                    className={`flex items-center ${
                      coin.change >= 0 ? 'text-green-400' : 'text-red-400'
                    }`}
                  >
                    {coin.change >= 0 ? (
                      <TrendingUp className="h-3 w-3 mr-0.5" />
                    ) : (
                      <TrendingDown className="h-3 w-3 mr-0.5" />
                    )}
                    {Math.abs(coin.change).toFixed(2)}%
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}