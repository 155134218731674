import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export default function DomainSaleBanner() {
  const [isVisible, setIsVisible] = useState(true);

  function handleClose() {
    setIsVisible(false);
  }

  if (!isVisible) {
    return null;
  }

  return (
    <div
      role="alert"
      aria-label="Domain sale notification"
      className="fixed bottom-0 left-0 right-0 z-50 transform"
    >
      <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-orange-900 text-white p-4 shadow-lg border-t border-orange-500/20 animate-slideUp">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <button
            onClick={handleClose}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-1 hover:bg-white/10 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-900"
            aria-label="Close banner"
          >
            <X className="h-5 w-5" />
          </button>
          
          <div className="flex items-center justify-center pr-8">
            <a
              href="https://www.godaddy.com/forsale/bitcoinlendr.com"
              target="_blank"
              rel="noopener noreferrer"
              className="group inline-flex items-center space-x-2 text-sm sm:text-base hover:text-orange-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-900 rounded-md"
            >
              <span className="font-medium">This domain is for sale</span>
              <span className="hidden sm:inline group-hover:translate-x-1 transition-transform duration-200">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}