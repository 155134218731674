import React from 'react';
import { DollarSign, TrendingUp, AlertCircle } from 'lucide-react';

export default function DashboardOverview() {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Overview</h2>
      
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <DollarSign className="h-6 w-6 text-orange-500" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">Total Borrowed</p>
              <p className="text-lg font-semibold text-gray-900">$25,000</p>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <TrendingUp className="h-6 w-6 text-green-500" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">Collateral Value</p>
              <p className="text-lg font-semibold text-gray-900">2.5 BTC</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="bg-orange-50 rounded-lg p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-orange-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-orange-800">
                Attention needed
              </h3>
              <div className="mt-2 text-sm text-orange-700">
                <p>
                  Your collateral ratio is approaching the minimum threshold. Consider
                  adding more collateral to avoid liquidation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}