import React from 'react';
import { X, Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import MarketTicker from './MarketTicker';
import StaggeredMenu from './StaggeredMenu';
import ThemeToggle from './ThemeToggle';

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <nav className="bg-white dark:bg-gray-900 shadow-lg fixed w-full z-50">
      <MarketTicker />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <span className="text-xl font-bold bg-gradient-to-r from-orange-500 to-orange-600 bg-clip-text text-transparent">
                BitcoinLendr
              </span>
            </Link>
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center space-x-8">
            <ThemeToggle />
            <Link to="/how-it-works" className="text-gray-700 dark:text-gray-300 hover:text-orange-500 dark:hover:text-orange-400 px-3 py-2 text-sm font-medium">
              How it Works
            </Link>
            <Link to="/pricing" className="text-gray-700 dark:text-gray-300 hover:text-orange-500 dark:hover:text-orange-400 px-3 py-2 text-sm font-medium">
              Pricing
            </Link>
            <div className="relative">
              <Link
                to="/login"
                className="bg-orange-500 text-white px-4 py-2 rounded-lg font-medium hover:bg-orange-600 transition-colors"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                Get Started
              </Link>
              {showTooltip && (
                <div className="absolute right-0 mt-2 w-72 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 border border-gray-200 dark:border-gray-700">
                  <div className="flex items-start">
                    <Info className="h-5 w-5 text-orange-500 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Demo Credentials:</p>
                      <p className="text-sm text-gray-800 dark:text-gray-200 mb-1">Email: admin@anyemail.com</p>
                      <p className="text-sm text-gray-800 dark:text-gray-200">Password: any 8+ characters</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 dark:text-gray-300 hover:text-orange-500 dark:hover:text-orange-400 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none"
            >
              {isOpen ? <X className="h-6 w-6" /> : <StaggeredMenu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <div className="px-3 py-2 flex items-center">
              <ThemeToggle />
              <span className="ml-3 text-sm text-gray-700 dark:text-gray-300"></span>
            </div>
            <Link
              to="/how-it-works"
              className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-orange-500 dark:hover:text-orange-400 hover:bg-gray-50 dark:hover:bg-gray-800"
            >
              How it Works
            </Link>
            <Link
              to="/pricing"
              className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-orange-500 dark:hover:text-orange-400 hover:bg-gray-50 dark:hover:bg-gray-800"
            >
              Pricing
            </Link>
            <div className="px-3 py-2">
              <Link
                to="/login"
                className="block w-full bg-orange-500 text-white px-4 py-2 rounded-lg font-medium hover:bg-orange-600 dark:hover:bg-orange-700 transition-colors text-center"
              >
                Get Started
              </Link>
              <div className="mt-2 p-3 bg-gray-50 dark:bg-gray-800 rounded-md">
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Demo Credentials:</p>
                <p className="text-sm text-gray-800 dark:text-gray-200 mb-1">Email: admin@anyemail.com</p>
                <p className="text-sm text-gray-800 dark:text-gray-200">Password: any 8+ characters</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}