import React from 'react';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import DashboardOverview from '../components/dashboard/DashboardOverview';
import LoanCalculator from '../components/loans/LoanCalculator';
import ActiveLoans from '../components/loans/ActiveLoans';

export default function Dashboard() {
  return (
    <DashboardLayout>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <DashboardOverview />
        <LoanCalculator />
      </div>
      <div className="mt-8">
        <ActiveLoans />
      </div>
    </DashboardLayout>
  );
}