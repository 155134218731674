import React from 'react';
import { ArrowRight, Wallet, LineChart, Clock } from 'lucide-react';
import Features from './Features';

function MobileAppMockup() {
  return (
    <div className="p-4 h-full flex flex-col text-white">
      {/* Status Bar */}
      <div className="flex justify-between items-center text-xs mb-6 px-2">
        <span>9:41</span>
        <div className="flex items-center space-x-1">
          <span>5G</span>
          <span>100%</span>
        </div>
      </div>

      {/* Balance Card */}
      <div className="bg-gradient-to-br from-orange-500 to-orange-600 rounded-2xl p-6 mb-6">
        <h2 className="text-sm font-medium opacity-90 mb-1">Total Balance</h2>
        <div className="flex items-baseline space-x-2">
          <span className="text-2xl font-bold">2.5 BTC</span>
          <span className="text-sm opacity-75">≈ $84,532.21</span>
        </div>
        <div className="mt-4 flex space-x-2">
          <button className="flex-1 bg-white/20 rounded-lg py-2 text-sm font-medium backdrop-blur-sm">
            Send
          </button>
          <button className="flex-1 bg-white/20 rounded-lg py-2 text-sm font-medium backdrop-blur-sm">
            Receive
          </button>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="flex flex-col items-center bg-gray-800/50 rounded-xl p-3">
          <Wallet className="h-6 w-6 mb-1" />
          <span className="text-xs">Borrow</span>
        </div>
        <div className="flex flex-col items-center bg-gray-800/50 rounded-xl p-3">
          <LineChart className="h-6 w-6 mb-1" />
          <span className="text-xs">Trade</span>
        </div>
        <div className="flex flex-col items-center bg-gray-800/50 rounded-xl p-3">
          <Clock className="h-6 w-6 mb-1" />
          <span className="text-xs">History</span>
        </div>
      </div>

      {/* Active Loans */}
      <div className="flex-1">
        <h3 className="text-sm font-medium mb-3">Active Loans</h3>
        <div className="bg-gray-800/50 rounded-xl p-4 mb-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <span className="text-sm font-medium">Loan #1</span>
              <p className="text-xs opacity-75">Due in 45 days</p>
            </div>
            <span className="text-sm font-medium">$25,000</span>
          </div>
          <div className="w-full bg-gray-700 rounded-full h-2">
            <div className="bg-orange-500 h-2 rounded-full" style={{ width: '65%' }} />
          </div>
        </div>
      </div>

      {/* Bottom Navigation */}
      <div className="flex justify-around py-4 bg-gray-800/50 rounded-xl mt-auto">
        <button className="text-orange-500">
          <Wallet className="h-6 w-6" />
        </button>
        <button className="opacity-50">
          <LineChart className="h-6 w-6" />
        </button>
        <button className="opacity-50">
          <Clock className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
}

export default function Hero() {
  return (
    <div className="flex-1 flex flex-col">
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-orange-900">
          <div className="absolute inset-0 opacity-10">
            <div className="absolute top-0 left-0 w-full h-full bg-repeat"
                 style={{
                   backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f97316' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                 }}
            />
          </div>
        </div>

        <div className="relative nav-spacing pb-20">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="lg:col-span-7 mt-8 sm:mt-12">
                <h1 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
                  <span className="block text-white">Borrow Against Your</span>
                  <span className="block text-orange-500 mt-2">Bitcoin Holdings</span>
                </h1>
                <p className="mt-6 text-base text-gray-200 sm:text-lg sm:max-w-xl md:text-xl">
                  Access instant loans using your Bitcoin as collateral. No credit checks, competitive rates, and complete
                  control over your digital assets.
                </p>
                <div className="mt-8 sm:mt-12 space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                  <button className="w-full sm:w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 md:py-4 md:text-lg md:px-10">
                    Start Borrowing
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </button>
                  <button className="w-full sm:w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-orange-100 hover:bg-orange-200 md:py-4 md:text-lg md:px-10">
                    Learn More
                  </button>
                </div>
                
                <div className="mt-8">
                  <a 
                    href="https://www.dynadot.com/market/user-listings/bitcoinlendr.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="domain-button inline-flex items-center px-6 py-3 border border-orange-300 text-base font-medium rounded-md text-orange-300 hover:bg-orange-500 hover:text-white hover:border-transparent transition-colors duration-200"
                  >
                    This Domain is for sale
                  </a>
                </div>
              </div>

              <div className="hidden lg:block lg:col-span-5">
                <div className="transform translate-x-8 translate-y-8">
                  <div className="iphone-mockup">
                    <div className="iphone-notch" />
                    <div className="iphone-mockup-screen">
                      <MobileAppMockup />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Features />
    </div>
  );
}