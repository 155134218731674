import React, { useState, useEffect } from 'react';
import { Calculator } from 'lucide-react';

export default function LoanCalculator() {
  const [collateral, setCollateral] = useState<number>(1);
  const [loanAmount, setLoanAmount] = useState<number>(20000);
  const [term, setTerm] = useState<number>(12);
  const [apr, setApr] = useState<number>(12);
  const [monthlyPayment, setMonthlyPayment] = useState<number>(0);

  useEffect(() => {
    const principal = loanAmount;
    const monthlyRate = apr / 100 / 12;
    const numberOfPayments = term;

    const payment =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    setMonthlyPayment(isNaN(payment) ? 0 : payment);
  }, [loanAmount, term, apr]);

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center mb-4">
        <Calculator className="h-6 w-6 text-orange-500 mr-2" />
        <h2 className="text-lg font-semibold text-gray-900">Loan Calculator</h2>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Bitcoin Collateral (BTC)
          </label>
          <input
            type="number"
            min="0.1"
            step="0.1"
            value={collateral}
            onChange={(e) => setCollateral(Number(e.target.value))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Loan Amount (USD)
          </label>
          <input
            type="number"
            min="1000"
            step="1000"
            value={loanAmount}
            onChange={(e) => setLoanAmount(Number(e.target.value))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Loan Term (months)
          </label>
          <select
            value={term}
            onChange={(e) => setTerm(Number(e.target.value))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option value="6">6 months</option>
            <option value="12">12 months</option>
            <option value="24">24 months</option>
            <option value="36">36 months</option>
          </select>
        </div>

        <div className="bg-gray-50 rounded-lg p-4 mt-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">Monthly Payment</p>
              <p className="text-lg font-semibold text-gray-900">
                ${monthlyPayment.toFixed(2)}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500">APR</p>
              <p className="text-lg font-semibold text-gray-900">{apr}%</p>
            </div>
          </div>
        </div>

        <button className="w-full bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          Apply for Loan
        </button>
      </div>
    </div>
  );
}