import React from 'react';
import { Wallet, ShieldCheck, Coins, ArrowRight } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const steps = [
  {
    title: 'Connect Your Wallet',
    description: 'Link your Bitcoin wallet to our platform. We support most major wallets and exchanges.',
    icon: Wallet,
    details: [
      'Multiple wallet support',
      'Simple one-click connection',
      'Secure authentication',
      'Real-time balance updates'
    ]
  },
  {
    title: 'Secure Your Collateral',
    description: 'Deposit your Bitcoin as collateral into our secure multi-signature vault.',
    icon: ShieldCheck,
    details: [
      'Multi-signature security',
      'Institutional grade storage',
      'Full insurance coverage',
      'Regular security audits'
    ]
  },
  {
    title: 'Receive Your Loan',
    description: 'Get instant funding in your preferred currency. No credit checks required.',
    icon: Coins,
    details: [
      'Instant approval',
      'Multiple currency options',
      'Competitive rates',
      'Flexible terms'
    ]
  }
];

export default function HowItWorks() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      
      {/* SEO-optimized meta title and description */}
      <div className="hidden">
        <h1>How Bitcoin Lending Works - Simple, Secure, and Instant Loans</h1>
        <p>
          Learn how to borrow against your Bitcoin in three simple steps. Connect your wallet,
          secure your collateral, and receive instant funding with competitive rates.
        </p>
      </div>

      {/* Compact Hero Section */}
      <div className="relative pt-32 pb-12 bg-gradient-to-br from-gray-900 via-gray-800 to-orange-900">
        <div className="absolute inset-0 overflow-hidden">
          <div 
            className="absolute inset-0 opacity-10"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23f97316' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
              backgroundRepeat: 'repeat'
            }}
          />
        </div>

        <div className="relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
                How It Works
              </h1>
              <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-300">
                Get started with BitcoinLendr in three simple steps. Our streamlined process
                makes borrowing against your Bitcoin holdings quick and secure.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Steps Section */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            {/* Connection line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-orange-500/20 hidden lg:block" />
            
            {/* Steps */}
            <div className="space-y-16">
              {steps.map((step, index) => {
                const Icon = step.icon;
                return (
                  <div key={step.title} className="relative">
                    <div className="relative z-10 lg:flex items-center">
                      <div className={`lg:w-1/2 ${index % 2 === 0 ? 'lg:pr-8' : 'lg:pl-8 lg:ml-auto'} mb-8 lg:mb-0`}>
                        <div className="bg-white rounded-2xl shadow-lg p-8 border border-gray-100 hover:border-orange-500 transition-colors duration-300">
                          <Icon className="h-12 w-12 text-orange-500 mb-5" />
                          <h3 className="text-2xl font-bold text-gray-900 mb-3">{index + 1}. {step.title}</h3>
                          <p className="text-gray-600 mb-6">{step.description}</p>
                          <ul className="space-y-3">
                            {step.details.map((detail) => (
                              <li key={detail} className="flex items-center text-gray-600">
                                <ArrowRight className="h-4 w-4 text-orange-500 mr-2" />
                                {detail}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="hidden lg:block w-12 h-12 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-orange-500 rounded-full border-4 border-gray-50" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* CTA Section */}
          <div className="mt-16 text-center">
            <button className="inline-flex items-center px-8 py-3 border border-transparent text-lg font-medium rounded-xl text-white bg-orange-500 hover:bg-orange-600 transition-colors duration-300">
              Start Borrowing Now
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>

          {/* Additional Info Section */}
          <div className="mt-20">
            <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
              Why Choose BitcoinLendr?
            </h2>
            <div className="grid gap-8 md:grid-cols-2">
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Institutional-Grade Security
                </h3>
                <p className="text-gray-600">
                  Your Bitcoin is stored in secure multi-signature vaults with comprehensive
                  insurance coverage. We use industry-leading security protocols to protect
                  your assets.
                </p>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Transparent Terms
                </h3>
                <p className="text-gray-600">
                  No hidden fees or complicated terms. We provide clear, straightforward
                  loan terms with competitive interest rates and flexible repayment options.
                </p>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Instant Processing
                </h3>
                <p className="text-gray-600">
                  Get your loan funded within minutes after collateral confirmation. Our
                  automated system ensures quick processing without compromising security.
                </p>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  24/7 Support
                </h3>
                <p className="text-gray-600">
                  Our dedicated support team is available around the clock to help you
                  with any questions or concerns about your loan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}