import React from 'react';
import { Shield, Coins, Clock } from 'lucide-react';

export default function Features() {
  return (
    <section className="bg-gray-900 mt-auto">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
          <div className="flex items-center space-x-4 p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700">
            <Shield className="h-8 w-8 text-orange-500" />
            <div>
              <h3 className="font-semibold text-white">Secure Storage</h3>
              <p className="text-gray-300 text-sm">Multi-signature protection</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700">
            <Coins className="h-8 w-8 text-orange-500" />
            <div>
              <h3 className="font-semibold text-white">Competitive Rates</h3>
              <p className="text-gray-300 text-sm">As low as 1% APR</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700">
            <Clock className="h-8 w-8 text-orange-500" />
            <div>
              <h3 className="font-semibold text-white">Instant Approval</h3>
              <p className="text-gray-300 text-sm">Get funded in minutes</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}