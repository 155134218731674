import React from 'react';

export default function StaggeredMenu({ className = '', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      {...props}
      className={`${className}`}
    >
      <line x1="6" y1="8" x2="16" y2="8" />
      <line x1="4" y1="12" x2="20" y2="12" />
      <line x1="8" y1="16" x2="14" y2="16" />
    </svg>
  );
}